'use client';

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faArrowsRotate, faCalendarDays, faCheck, faClockRotateLeft, faHourglass, faHourglassEnd, faHourglassHalf, faHourglassStart, faKey, faMoon, faRightFromBracket, faSackDollar, faShield, faSun, faUser, faX } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import Image from 'next/image';
import { useTheme } from 'next-themes';
import { usePathname, useRouter } from 'next/navigation';
import { Tooltip } from 'flowbite-react';
import type { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { DateTime } from 'luxon';
import { sendGTMEvent } from '@next/third-parties/google';
import { useAppSelector } from '@/lib/store';
import { getCurrentUser, getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice';
import Coupon from '@/app/components/Coupon';
import { getBrowseFiltersAspectRatio, getBrowseFiltersPage, getBrowseFiltersPerPage, getBrowseFiltersSize, getBrowseFiltersStyle, getBrowseFiltersStyleType } from '@/lib/features/browseFilters/browseFiltersSlice';
import { getUserShortFullName } from '@/lib/utils';
import { getGeneratorSettingsGeneratingStatus } from '@/lib/features/generatorSettings/generatorSettingsSlice';
import { IMAGE_GENERATION_STATUS_NAME } from '@/lib/constants';
import { EnumImageGenerationStatus } from '../../../../../../../lib/database/lib/generated/enums';
import { getIsReduxInitialized } from '@/lib/features/registry/registrySlice';
export default function Navbar(): React.ReactElement | null {
  const router = useRouter();
  const pathname = usePathname();
  const isReduxInitialized = useAppSelector(getIsReduxInitialized);
  const user = useAppSelector(getCurrentUser);
  const isLoggedIn = useAppSelector(getCurrentUserIsLoggedIn);
  const generatingStatus = useAppSelector(getGeneratorSettingsGeneratingStatus);
  const browseFiltersPage = useAppSelector(getBrowseFiltersPage);
  const browseFiltersPerPage = useAppSelector(getBrowseFiltersPerPage);
  const browseFiltersStyleType = useAppSelector(getBrowseFiltersStyleType);
  const browseFiltersStyle = useAppSelector(getBrowseFiltersStyle);
  const browseFiltersSize = useAppSelector(getBrowseFiltersSize);
  const browseFiltersAspectRatio = useAppSelector(getBrowseFiltersAspectRatio);
  const [currentTheme, setCurrentTheme] = React.useState<string>('light');
  const {
    systemTheme,
    theme,
    setTheme
  } = useTheme();
  React.useEffect(() => {
    setCurrentTheme((theme === 'system' ? systemTheme : theme) ?? 'light');
  }, [systemTheme, theme]);
  const [isNavbarMenuOpen, setIsNavbarMenuOpen] = React.useState<boolean>(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [hourglassIcon, setHourglassIcon] = React.useState<IconDefinition>(faHourglassStart);
  const [apiUrl, setApiUrl] = React.useState<string>(`${process.env['NEXT_PUBLIC_PICTRIX_API_URL']}/v1/docs`);
  const navbarMenuRef = React.useRef<HTMLDivElement | null>(null);
  const navbarMenuButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const searchButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const userMenuRef = React.useRef<HTMLDivElement | null>(null);
  const userMenuButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const avatarRef = React.useRef<HTMLImageElement | null>(null);
  const toggleTheme = (): void => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    if (process.env['NEXT_PUBLIC_PICTRIX_STAGE'] === 'prod') {
      sendGTMEvent({
        event: 'set_theme',
        user_id: user?.id,
        theme: newTheme
      });
    }
  };
  const handleSearch = (currentSearchTerm: string): void => {
    void (async () => {
      const data: Record<string, string> = {
        searchTerm: currentSearchTerm
      };
      if (pathname === '/browse') {
        if (browseFiltersPage) {
          data['page'] = browseFiltersPage.toString();
        }
        if (browseFiltersPerPage) {
          data['perPage'] = browseFiltersPerPage.toString();
        }
        if (browseFiltersStyleType) {
          data['styleType'] = browseFiltersStyleType.code;
        }
        if (browseFiltersStyle) {
          data['style'] = browseFiltersStyle.code;
        }
        if (browseFiltersSize) {
          data['size'] = browseFiltersSize.code;
        }
        if (browseFiltersAspectRatio) {
          data['aspectRatio'] = browseFiltersAspectRatio.toString();
        }
      }
      router.push(`/browse?${new URLSearchParams(data).toString()}`);
    })();
  };
  React.useEffect(() => {
    setApiUrl(`${process.env['NEXT_PUBLIC_PICTRIX_API_URL']}/v1/docs?theme=${currentTheme === 'dark' ? 'dark' : 'light'}`);
  }, [currentTheme]);
  React.useEffect(() => {
    if (avatarRef?.current && user) {
      avatarRef.current.src = `${user.picture_url}?${DateTime.fromISO(user.updated_at).toUnixInteger()}`;
    }
  }, [user]);
  React.useEffect(() => {
    const el = document.getElementById('navbar');
    if (!el) {
      return () => {};
    }
    const observer = new IntersectionObserver(([e]) => e?.target.classList.toggle('is-pinned', e?.intersectionRatio < 1), {
      threshold: [1]
    });
    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  });
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (isNavbarMenuOpen) {
        if (navbarMenuRef.current && !navbarMenuRef.current.contains((event.target as Node)) && navbarMenuButtonRef.current && !navbarMenuButtonRef.current.contains((event.target as Node)) && searchButtonRef.current && !searchButtonRef.current.contains((event.target as Node))) {
          setIsNavbarMenuOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNavbarMenuOpen, navbarMenuRef]);
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (isUserMenuOpen) {
        if (userMenuRef.current && !userMenuRef.current.contains((event.target as Node)) && userMenuButtonRef.current && !userMenuButtonRef.current.contains((event.target as Node))) {
          setIsUserMenuOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isUserMenuOpen, userMenuRef]);
  const setNewHourglassIcon = React.useCallback(() => {
    if (hourglassIcon === faHourglassStart) {
      setHourglassIcon(faHourglassHalf);
    } else if (hourglassIcon === faHourglassHalf) {
      setHourglassIcon(faHourglassEnd);
    } else if (hourglassIcon === faHourglassEnd) {
      setHourglassIcon(faHourglassStart);
    }
  }, [hourglassIcon]);
  React.useEffect(() => {
    let hourglassInterval: ReturnType<typeof setInterval>;
    if (generatingStatus === EnumImageGenerationStatus.IN_QUEUE) {
      hourglassInterval = setInterval(setNewHourglassIcon, 500);
    }
    return () => {
      if (hourglassInterval) {
        clearInterval(hourglassInterval);
      }
    };
  }, [generatingStatus, setNewHourglassIcon]);
  const userInitialsParts = [];
  if (user?.first_name) {
    userInitialsParts.push(user?.first_name?.charAt(0).toUpperCase());
  }
  if (user?.last_name) {
    userInitialsParts.push(user?.last_name?.charAt(0).toUpperCase());
  }
  const userInitials = userInitialsParts.length ? userInitialsParts.join('') : null;
  const numCreditsLeft = user?.user_credits ? user.user_credits.membership_credits + user.user_credits.purchase_credits : 0;
  const generatingBadgeClassMap = {
    [EnumImageGenerationStatus.INITIALIZING]: 'generating-badge-initializing',
    [EnumImageGenerationStatus.IN_QUEUE]: 'generating-badge-in-queue',
    [EnumImageGenerationStatus.IN_PROGRESS]: 'generating-badge-in-progress',
    [EnumImageGenerationStatus.CONVERTING_TO_SVG]: 'generating-badge-converting-to-svg',
    [EnumImageGenerationStatus.COMPLETED]: 'generating-badge-completed',
    [EnumImageGenerationStatus.CANCELLED]: 'generating-badge-cancelled',
    [EnumImageGenerationStatus.FAILED]: 'generating-badge-failed',
    [EnumImageGenerationStatus.TIMED_OUT]: 'generating-badge-timed-out'
  };
  return <nav id="navbar" className="border-border dark:border-border-dark sticky -top-px start-0 z-40 w-full bg-neutral-100 dark:bg-neutral-900 [&.is-pinned]:border-y" data-sentry-component="Navbar" data-sentry-source-file="index.tsx">
      {!user?.user_membership && <Coupon />}
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
        <Link href="/" className="z-1 relative inline-block align-middle" title="Text prompt to Image" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <Image src="/images/logo.svg" className="dark:invert" width={158} height={40} alt="Text prompt to Image" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </Link>
        <div className="flex space-x-3 lg:order-2 xl:space-x-0 rtl:space-x-reverse">
          <button type="button" className="text-disabled-text dark:text-secondary-text-dark hidden size-10 rounded-lg p-2 px-2.5 hover:cursor-pointer hover:bg-neutral-200 focus:outline-none sm:block xl:mr-2 dark:hover:bg-neutral-700" onClick={toggleTheme}>
            <span className="dark:hidden">
              <FontAwesomeIcon icon={faMoon} className="size-5 translate-y-0.5" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" />
            </span>
            <span className="hidden dark:block">
              <FontAwesomeIcon icon={faSun} className="size-5 translate-y-0.5" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" />
            </span>
            <span className="sr-only">Toggle theme</span>
          </button>
          <button ref={searchButtonRef} type="button" className="text-disabled-text dark:text-secondary-text-dark mr-1 hidden rounded-lg p-2.5 text-sm hover:bg-neutral-100 focus:outline-none sm:block lg:hidden dark:hover:bg-neutral-700" onClick={() => {
          setIsNavbarMenuOpen(!isNavbarMenuOpen);
        }}>
            <svg className="size-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" data-sentry-element="svg" data-sentry-source-file="index.tsx">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" data-sentry-element="path" data-sentry-source-file="index.tsx" />
            </svg>
            <span className="sr-only">Search images</span>
          </button>
          <div className="relative hidden lg:mr-4 lg:block xl:pr-4">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                <svg className="text-disabled-text dark:text-secondary-text-dark size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" data-sentry-element="svg" data-sentry-source-file="index.tsx">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" data-sentry-element="path" data-sentry-source-file="index.tsx" />
                </svg>
                <span className="sr-only">Search icon</span>
              </div>
              <form onSubmit={(event: React.FormEvent) => {
              event.preventDefault();
              handleSearch(searchTerm);
              setSearchTerm('');
              setIsNavbarMenuOpen(false);
            }}>
                <input type="text" enterKeyHint="search" value={searchTerm} className="input w-52 pe-14 ps-10" placeholder="Search..." onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTerm(e.target.value);
              }} />
                <button type="submit" className="button-tiny button-primary absolute end-2 top-1/2 flex -translate-y-1/2 items-center">
                  Submit
                </button>
              </form>
            </div>
          </div>
          {!isReduxInitialized && <div className="flex items-center justify-center" role="status">
              <svg aria-hidden="true" className="spinner-on-dark size-6" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>}
          {isReduxInitialized && !isLoggedIn && <Link href="/login" className={`${!isReduxInitialized ? 'hidden' : ''} bg-primary hover:bg-primary-hover dark:bg-primary-dark hover:dark:bg-primary-dark-hover flex rounded-full px-4 py-2 text-center align-middle text-xs font-medium text-white transition-colors focus:outline-none`}>
              <span className="text-primary-contrast-primary-text dark:text-primary-dark-contrast-primary-text my-auto font-bold">
                Sign In
              </span>
            </Link>}
          {isReduxInitialized && isLoggedIn && <div className={`${!isReduxInitialized ? 'hidden' : ''} relative inline`}>
              <button ref={userMenuButtonRef} type="button" className="relative flex size-10 rounded-full text-sm md:me-0" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom" onClick={() => {
            setIsUserMenuOpen(!isUserMenuOpen);
          }}>
                <span className="sr-only">Open user menu</span>
                <div className="relative">
                  {user?.picture_url ? <Image ref={avatarRef} width={40} height={40} className="size-10 rounded-full" src={user.picture_url} alt="avatar" /> : <div className="inline-flex size-10 items-center justify-center overflow-hidden rounded-full bg-neutral-300 dark:bg-neutral-600">
                      {userInitials ? <span className="text-secondary-text dark:text-secondary-text-dark font-bold">{userInitials}</span> : <FontAwesomeIcon icon={faUser} className="text-disabled-text size-5" />}
                    </div>}
                  {numCreditsLeft > 10 && <div className="counter-badge counter-badge-primary">{numCreditsLeft}</div>}
                  {numCreditsLeft > 0 && numCreditsLeft <= 10 && <div className="counter-badge counter-badge-warning">{numCreditsLeft}</div>}
                  {numCreditsLeft === 0 && <div className="counter-badge counter-badge-danger">{numCreditsLeft}</div>}
                  {generatingStatus !== null && <div className={`generating-badge ${generatingBadgeClassMap[generatingStatus]}`}>
                      <Tooltip placement="bottom-end" arrow={false} content={<div className="text-nowrap">Status: {IMAGE_GENERATION_STATUS_NAME[generatingStatus]}</div>}>
                        {generatingStatus === EnumImageGenerationStatus.INITIALIZING && <svg aria-hidden="true" role="status" className="spinner-on-primary relative top-[-2px] size-2.5" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                          </svg>}
                        {generatingStatus === EnumImageGenerationStatus.IN_QUEUE && <FontAwesomeIcon icon={hourglassIcon} className="relative top-[-3px] size-[9px]" />}
                        {generatingStatus === EnumImageGenerationStatus.IN_PROGRESS && <FontAwesomeIcon icon={faArrowsRotate} className="relative top-[-3px] size-2.5 animate-spin" />}
                        {generatingStatus === EnumImageGenerationStatus.CONVERTING_TO_SVG && <svg className="size-2.5 animate-pulse" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.91659 0.833334H1.24992C0.881252 0.833334 0.583252 1.13133 0.583252 1.5V4.16667C0.583252 4.53533 0.881252 4.83333 1.24992 4.83333H3.91659C4.28525 4.83333 4.58325 4.53533 4.58325 4.16667V1.5C4.58325 1.13133 4.28525 0.833334 3.91659 0.833334Z" fill="currentColor" />
                            <path d="M13.2499 0.833334H10.5833C10.2146 0.833334 9.91659 1.13133 9.91659 1.5V4.16667C9.91659 4.53533 10.2146 4.83333 10.5833 4.83333H13.2499C13.6186 4.83333 13.9166 4.53533 13.9166 4.16667V1.5C13.9166 1.13133 13.6186 0.833334 13.2499 0.833334Z" fill="currentColor" />
                            <path d="M3.91659 10.1667H1.24992C0.881252 10.1667 0.583252 10.4647 0.583252 10.8333V13.5C0.583252 13.8687 0.881252 14.1667 1.24992 14.1667H3.91659C4.28525 14.1667 4.58325 13.8687 4.58325 13.5V10.8333C4.58325 10.4647 4.28525 10.1667 3.91659 10.1667Z" fill="currentColor" />
                            <path d="M13.2499 10.1667H10.5833C10.2146 10.1667 9.91659 10.4647 9.91659 10.8333V13.5C9.91659 13.8687 10.2146 14.1667 10.5833 14.1667H13.2499C13.6186 14.1667 13.9166 13.8687 13.9166 13.5V10.8333C13.9166 10.4647 13.6186 10.1667 13.2499 10.1667Z" fill="currentColor" />
                            <path d="M8.58325 2.16667H5.91659C5.54792 2.16667 5.24992 2.46467 5.24992 2.83333C5.24992 3.202 5.54792 3.5 5.91659 3.5H8.58325C8.95192 3.5 9.24992 3.202 9.24992 2.83333C9.24992 2.46467 8.95192 2.16667 8.58325 2.16667Z" fill="currentColor" />
                            <path d="M8.58325 11.5H5.91659C5.54792 11.5 5.24992 11.798 5.24992 12.1667C5.24992 12.5353 5.54792 12.8333 5.91659 12.8333H8.58325C8.95192 12.8333 9.24992 12.5353 9.24992 12.1667C9.24992 11.798 8.95192 11.5 8.58325 11.5Z" fill="currentColor" />
                            <path d="M3.24992 8.83333V6.16667C3.24992 5.798 2.95192 5.5 2.58325 5.5C2.21459 5.5 1.91659 5.798 1.91659 6.16667V8.83333C1.91659 9.202 2.21459 9.5 2.58325 9.5C2.95192 9.5 3.24992 9.202 3.24992 8.83333Z" fill="currentColor" />
                            <path d="M12.5833 8.83333V6.16667C12.5833 5.798 12.2853 5.5 11.9166 5.5C11.5479 5.5 11.2499 5.798 11.2499 6.16667V8.83333C11.2499 9.202 11.5479 9.5 11.9166 9.5C12.2853 9.5 12.5833 9.202 12.5833 8.83333Z" fill="currentColor" />
                          </svg>}
                        {generatingStatus === EnumImageGenerationStatus.COMPLETED && <FontAwesomeIcon icon={faCheck} className="relative top-[-3px] size-2.5" />}
                        {generatingStatus === EnumImageGenerationStatus.CANCELLED && <FontAwesomeIcon icon={faX} className="relative top-[-3px] size-2.5" />}
                        {generatingStatus === EnumImageGenerationStatus.FAILED && <FontAwesomeIcon icon={faX} className="relative top-[-3px] size-2.5" />}
                        {generatingStatus === EnumImageGenerationStatus.TIMED_OUT && <FontAwesomeIcon icon={faHourglass} className="relative top-[-3px] size-[9px]" />}
                      </Tooltip>
                    </div>}
                </div>
              </button>
              <div ref={userMenuRef} className={`absolute end-0 top-12 ${!isUserMenuOpen ? 'hidden' : ''} divide-border dark:divide-border-dark shadow-shadow dark:shadow-shadow-dark list-none divide-y rounded-lg bg-white text-left text-base shadow-lg dark:bg-neutral-700`} id="user-dropdown">
                <div className="px-4 py-3">
                  <span className="text-primary-text dark:text-primary-text-dark block text-sm">{user && getUserShortFullName(user)}</span>
                  <span className="text-disabled-text dark:text-secondary-text-dark block truncate text-sm">{user?.email}</span>
                </div>
                <div className="py-2">
                  <Link href="/pricing" className="text-primary-text dark:text-primary-text-dark flex items-center gap-3 text-nowrap px-4 py-2 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-600">
                    <FontAwesomeIcon icon={faSackDollar} className="size-4" /> Credits: {numCreditsLeft}
                  </Link>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  <li>
                    <Link href="/history" className="text-secondary-text dark:text-secondary-text-dark flex items-center gap-3 px-4 py-2 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-600 dark:hover:text-white" onClick={() => {
                  setIsUserMenuOpen(!isUserMenuOpen);
                }}>
                      <FontAwesomeIcon icon={faClockRotateLeft} className="size-4" />
                      History
                    </Link>
                  </li>
                  <hr className="border-border dark:border-border-dark my-2 border-b border-t-0" />
                  {[{
                name: 'Account',
                url: '/account',
                icon: faAddressCard
              }, {
                name: 'Subscription',
                url: '/subscription',
                icon: faCalendarDays
              }, {
                name: 'Security',
                url: '/security',
                icon: faShield
              }, {
                name: 'API Key',
                url: '/api-key',
                icon: faKey
              }].map(link => <li key={`userMenuLink_${link.name}`}>
                      <Link href={link.url} className="text-secondary-text dark:text-secondary-text-dark flex items-center gap-3 px-4 py-2 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-600 dark:hover:text-white" onClick={() => {
                  setIsUserMenuOpen(!isUserMenuOpen);
                }}>
                        <FontAwesomeIcon icon={link.icon} className="size-4" />
                        {link.name}
                      </Link>
                    </li>)}
                  <hr className="border-border dark:border-border-dark my-2 border-b border-t-0" />
                  <li>
                    <Link href="/signout" className="text-secondary-text dark:text-secondary-text-dark flex items-center gap-3 px-4 py-2 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-600 dark:hover:text-white" onClick={() => {
                  setIsUserMenuOpen(!isUserMenuOpen);
                }}>
                      <FontAwesomeIcon icon={faRightFromBracket} className="size-4" />
                      Sign out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>}
          <button ref={navbarMenuButtonRef} id="navbar-sticky-trigger" data-collapse-toggle="navbar-sticky" type="button" className="text-disabled-text dark:text-secondary-text-dark inline-flex size-10 items-center justify-center rounded-lg p-2 text-sm hover:bg-neutral-100 focus:outline-none lg:hidden dark:hover:bg-neutral-700" aria-controls="navbar-sticky" aria-expanded={isNavbarMenuOpen} onClick={() => {
          setIsNavbarMenuOpen(!isNavbarMenuOpen);
        }}>
            <span className="sr-only">Open main menu</span>
            <svg className="size-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14" data-sentry-element="svg" data-sentry-source-file="index.tsx">
              <path stroke="currentColor " strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" data-sentry-element="path" data-sentry-source-file="index.tsx" />
            </svg>
          </button>
        </div>
        <div ref={navbarMenuRef} className={`mx-4 ${!isNavbarMenuOpen ? 'hidden' : ''} w-full items-center justify-between lg:order-1 lg:flex lg:w-auto`} id="navbar-sticky">
          <div className="mt-4 flex lg:hidden">
            <div className="relative w-full">
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                  <svg className="text-disabled-text dark:text-secondary-text-dark size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" data-sentry-element="svg" data-sentry-source-file="index.tsx">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" data-sentry-element="path" data-sentry-source-file="index.tsx" />
                  </svg>
                  <span className="sr-only">Search icon</span>
                </div>
                <form onSubmit={(event: React.FormEvent) => {
                event.preventDefault();
                handleSearch(searchTerm);
                setSearchTerm('');
                setIsNavbarMenuOpen(false);
              }}>
                  <input type="text" enterKeyHint="search" value={searchTerm} className="input w-full pe-14 ps-10" placeholder="Search..." onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(e.target.value);
                }} />
                  <button type="submit" className="button-tiny button-primary absolute end-2 top-1/2 flex -translate-y-1/2 items-center">
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="ml-2 sm:hidden">
              <button type="button" className="text-disabled-text dark:text-secondary-text-dark size-10 rounded-lg p-2 px-2.5 hover:cursor-pointer hover:bg-neutral-100 focus:outline-none xl:mr-2 dark:hover:bg-neutral-700" onClick={toggleTheme}>
                <span className="dark:hidden">
                  <FontAwesomeIcon icon={faMoon} className="size-5" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" />
                </span>
                <span className="hidden dark:block">
                  <FontAwesomeIcon icon={faSun} className="size-5" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" />
                </span>
                <span className="sr-only">Toggle theme</span>
              </button>
            </div>
          </div>
          <ul className="border-border dark:border-border-dark mt-4 flex flex-col rounded-lg border bg-neutral-50 p-4 text-sm font-medium lg:mt-0 lg:flex-row lg:space-x-2 lg:border-0 lg:bg-transparent lg:p-0 rtl:space-x-reverse dark:bg-neutral-800 lg:dark:bg-neutral-900">
            {[{
            name: 'Home',
            url: '/',
            membersOnly: false,
            publicOnly: false
          }, {
            name: 'Generate',
            url: '/generate',
            membersOnly: true,
            publicOnly: false
          }, {
            name: 'Browse',
            url: '/browse',
            membersOnly: false,
            publicOnly: false
          }, {
            name: 'Pricing',
            url: '/pricing',
            membersOnly: false,
            publicOnly: false
          }, {
            name: 'API',
            url: apiUrl,
            membersOnly: false,
            publicOnly: false
          }, {
            name: 'Contact',
            url: '/contact',
            membersOnly: false,
            publicOnly: false
          }].filter(link => link.membersOnly && isLoggedIn || link.publicOnly && !isLoggedIn || !link.membersOnly && !link.publicOnly).map(link => <li key={`menuLink_${link.name}`}>
                  {pathname === link.url ? <Link href={link.url} className="border-b-link-text-hover dark:border-b-link-text-dark-hover bg-primary dark:bg-primary-dark dark:text-primary-dark-contrast-primary-text lg:text-link-text-hover dark:lg:text-link-text-dark-hover text-primary-contrast-primary-text block rounded px-3 py-2 lg:rounded-none lg:border-b-4 lg:bg-transparent lg:px-2 lg:py-1 lg:dark:bg-transparent" aria-current="page" onClick={() => {
              setIsNavbarMenuOpen(false);
            }}>
                      {link.name}
                    </Link> : <Link href={link.url} className="lg:hover:text-link-text-hover lg:dark:hover:text-link-text-dark-hover dark:text-primary-text-dark text-primary-text dark:border-border-dark block rounded border-b-0 px-3 py-2 transition-colors hover:bg-neutral-100 lg:px-2 lg:py-1 lg:hover:bg-transparent dark:hover:bg-neutral-700 lg:dark:hover:bg-transparent" onClick={() => {
              setIsNavbarMenuOpen(false);
            }}>
                      {link.name}
                    </Link>}
                </li>)}
          </ul>
        </div>
      </div>
    </nav>;
}