'use client';

import * as React from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { DateTime } from 'luxon';
import { Oooh_Baby } from 'next/font/google';
import { useAppSelector } from '@/lib/store';
import { getFirstActivePublicCoupon } from '@/lib/features/coupons/couponsSlice';
const ooohBaby = Oooh_Baby({
  weight: ['400'],
  subsets: ['latin'],
  display: 'swap'
});
export default function Coupon(): React.ReactElement | null {
  const coupon = useAppSelector(getFirstActivePublicCoupon);
  const [expiryDate, setExpiryDate] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (coupon) {
      setExpiryDate(coupon.expires_at ? DateTime.fromISO(coupon.expires_at).toFormat('LLL d, yyyy') : null);
    }
  }, [coupon]);
  if (!coupon || !expiryDate && coupon.expires_at) {
    return null;
  }
  return <div data-sentry-component="Coupon" data-sentry-source-file="index.tsx">
      <Link href="/pricing" className="bg-primary dark:bg-primary-dark text-primary-contrast-primary-text dark:text-primary-dark-contrast-primary-text bg-flash animate-flash inline-block w-full p-2 px-8" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <div className="mx-auto flex flex-col items-center justify-center gap-8 gap-y-2 sm:flex-row md:gap-12">
          <span className="border-primary dark:border-primary-dark text-primary dark:text-primary-dark hidden shrink-0 items-center gap-2 border border-dashed bg-yellow-300 px-3 py-1 text-center font-extrabold lg:flex">
            {coupon.percent_off && <p className="text-nowrap">{(+coupon.percent_off).toFixed(0)}% OFF</p>}
            {coupon.amount_off && <p className="text-nowrap">${coupon.amount_off} OFF</p>}
            {coupon.duration_in_months && <p className="flex items-center text-xs font-extrabold">for {coupon.duration_in_months} months</p>}
          </span>
          <div className="flex items-center gap-4">
            <div className="">
              <FontAwesomeIcon icon={faGift} width={16} height={16} className="text-primary-contrast-primary-text dark:text-primary-dark-contrast-primary-text size-5" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" />
            </div>
            <span className={`${ooohBaby.className} text-nowrap text-2xl`}>
              <strong>{coupon.name}</strong>
            </span>
          </div>
          <span className="border-primary dark:border-primary-dark text-primary dark:text-primary-dark flex shrink-0 items-center gap-2 border border-dashed bg-yellow-300 px-3 py-1 text-center font-extrabold lg:hidden">
            {coupon.percent_off && <p className="text-nowrap">{(+coupon.percent_off).toFixed(0)}% OFF</p>}
            {coupon.amount_off && <p className="text-nowrap">${coupon.amount_off} OFF</p>}
            {coupon.duration_in_months && <p className="flex items-center text-xs font-extrabold">for {coupon.duration_in_months} months</p>}
          </span>
          <div className="flex flex-col items-center text-xs sm:items-start">
            {expiryDate ? <>
                <p>Applied automatically at</p>{' '}
                <p>
                  checkout until <b className="font-extrabold">{expiryDate}</b>
                </p>
              </> : <p>Applied automatically at checkout</p>}
          </div>
          <span className="border-primary dark:border-primary-dark text-primary dark:text-primary-dark hidden shrink-0 items-center gap-2 border border-dashed bg-yellow-300 px-3 py-1 text-center font-extrabold lg:flex">
            {coupon.percent_off && <p className="text-nowrap">{(+coupon.percent_off).toFixed(0)}% OFF</p>}
            {coupon.amount_off && <p className="text-nowrap">${coupon.amount_off} OFF</p>}
            {coupon.duration_in_months && <p className="flex items-center text-xs font-extrabold">for {coupon.duration_in_months} months</p>}
          </span>
        </div>
      </Link>
    </div>;
}